@use 'media-queries' as mq;

.container {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  @include mq.tablet {
    // display: grid;
    // grid-template-columns: 50% calc(50% - 20px);
  }
}
.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: #8080809c;
  padding: 10px 60px;
  border-radius: 20px;

  span {
    color: var(--mode-color);
    font-family: var(--secondary-font-family);
  }
  .title {
    font-size: 80px;
    line-height: 100px;
    @include mq.large-tablet {
      font-size: 100px;
      line-height: 120px;
    }
  }
  .information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    width: 100%;
    .top,
    .middle {
      font-size: 50px;
      line-height: 70px;
      @include mq.large-tablet {
        font-size: 80px;
        line-height: 100px;
      }
    }
    .middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        white-space: nowrap;
      }
      .icon {
        cursor: pointer;
        color: var(--mode-color);
        width: 100%;
      }
    }
    .bottom {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: var(--grey-300);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      .countdown {
        color: var(--mode-color);
        text-transform: uppercase;
      }
      @include mq.large-tablet {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.chad-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @include mq.tablet {
  }
}
.stage {
  font-family: var(--primary-font-family);
  color: var(--primary-color);
  font-size: 25px;
  line-height: 32px;
  text-align: center;

  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }
}
.mint-data {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: center;
  gap: 20px;
  @include mq.medium-window {
    display: flex;
    row-gap: unset;
  }
  .action-container {
    grid-column: 1/-1;
  }
  .value-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
    .value {
      font-size: 25px;
      line-height: 32px;
      white-space: nowrap;
      font-family: var(--secondary-font-family);
      .text {
        font-family: var(--primary-font-family);
        color: var(--primary-color);
      }
      @include mq.tablet {
        font-size: 33px;
        line-height: 41px;
      }
    }
    .label {
      text-transform: capitalize;
      font-size: 19px;
      line-height: 23px;
      font-weight: 300;
      @include mq.tablet {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  .price {
    position: relative;
    height: inherit;
    padding-right: 20px;
    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 1px;
      right: 0;
      top: 0;
      background-color: var(--mode-color);
    }
  }
}

.checker {
  margin-top: auto;
  width: 100%;
}
