.container {
  display: flex;
  align-items: center;
  gap: 12px;
  .icon {
    color: var(--grey-300);
    width: 41px;
    height: 41px;
    cursor: pointer;
  }
}
