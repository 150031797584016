@use 'media-queries' as mq;
.container {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;
  min-height: 100vh;
}
.content,
.header {
  position: relative;
  z-index: 5;
  padding: 30px 3% 0;
  @include mq.large-tablet {
    padding: 0 10% 0;
  }
}
.content {
  container-type: inline-size;
  display: flex;
  flex-direction: column;
}
