@use 'media-queries' as mq;

.container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 15px;
  padding-top: 40px;
  .top {
    grid-row: 1/2;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .logo {
    grid-column: 1/-1;
  }
  .icon {
    color: var(--mode-color);
    width: 41px;
    height: 41px;
    transition: all 500ms ease-in-out;
    cursor: pointer;
    &:hover {
      color: var(--primary-color);
    }
  }
}

@include mq.tablet {
  //.container {
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-evenly;
  //  gap: 0;
  //}
}
