.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--grey-300) 0 0 no-repeat padding-box;
  border-radius: 25px;
}

.success-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}

.link {
  font-weight: 500;
  color: black;
}

.paper {
  :global(.MuiPaper-root) {
    background: var(--grey-300) 0 0 no-repeat padding-box;
    border-radius: 46px;
    padding: 28px 30px 28px 30px;
    min-width: 250px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    color: var(--white);
  }
  :global(.MuiBackdrop-root) {
    background-color: var(--black);
    opacity: 0.92 !important;
  }
  :global(.MuiCircularProgress-root) {
    color: var(--mode-color);
  }
}
