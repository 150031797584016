@use 'button' as btn;
@use 'media-queries' as mq;

.btn {
  @include btn.button-base;
  gap: 14px;
  color: var(--btn-color, var(--black));
  padding: var(--btn-padding-y, 6px) var(--btn-padding-x, 17px);
  font-family: var(--primary-font-family);
  text-transform: uppercase;
  font-size: var(--btn-font-size, 16px);
  transition: all 240ms ease-in-out;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.08);
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.15);
  }

  @include mq.large-handset {
    padding: var(--btn-padding-y, 6px) var(--btn-padding-x, 36px);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.full-width {
  width: 100%;
}

.contained {
  background-color: var(--btn-bg-color, var(--mode-color));

  &.contrasted {
    color: var(--btn-color, var(--on-contrast-primary-color));
    background-color: var(--btn-bg-color, var(--black));
  }
}

.outlined {
  background-color: transparent;
  @include btn.bordered-button;
}
