@use 'media-queries' as mq;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    flex-direction: row;
  }
  .text {
    font-size: 90px;
    line-height: 90px;
    @include mq.large-tablet {
      font-size: 99px;
      line-height: 138px;
    }
  }
  .logo {
    width: 100%;
    max-width: 700px;
    min-height: 90px;
    height: 100%;
    cursor: pointer;
    padding: 0;
    object-fit: contain;
    @media (max-width: 600px) {
      min-height: 138px;
    }
  }
}
