.container {
  display: flex;
  justify-content: center;
}
.status {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.not-connected {
  background-color: var(--error-color);
}
.connected {
  background-color: var(--success-color);
}
